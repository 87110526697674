<template>
	<div class='container pb120'>
		<aboutUsBorder :title="title" :content='content'></aboutUsBorder>
		<!-- 轮播图 -->
		<div class="swiper_box mr40 ml40 mt60 mb120">
			<div class="swiperBox relative">
				<div class="swiper-container">
					<div class="swiper-wrapper">
						<div class="swiper-slide" v-for="(item,i) in list" :key="i">
							<div class="w600 h900 ">
								<!-- <div style="background: #979797;"></div> -->
								<img :src="item.pcPic" class="w-max h690" style="display: block;">
								<div class="w-max bg-0 h210 color-f fs18 text-center pt60 pl30 pr30 lh45">{{item.caseName}}</div>
							</div>
						</div>
						
					</div>
					<div class="swiper-button-prev swiper-button-black"></div>
					<div class="swiper-button-next swiper-button-black"></div>
					<!-- Add Pagination -->
					<!-- <div class="swiper-pagination"></div> -->
				
					<!-- <div class="swiper_mask"></div> -->
				</div>
			</div>
		</div>
		<!-- //轮播图 -->

		<!-- 内容 -->
		<div class="text-center fs18 color9D">铭智生科致力于公共卫生领域与科学防疫领域，经过多年的诚信经营、依靠优质的服务体系和过硬的产品质量，收获了各个领域的企事业与机关单位的支持信任。
		</div>
		<!-- //内容 -->

		<!-- 循环项 -->
		<div class='flex flex-wrap'>
			<div class="w530 h240 mt60 mr65 box_item pt15 pb15 flex col-center row-center " style="background-color: #f8f8f8;" v-for="(item,i) in imgList"
				:key="i">
				<img :src="item.img" alt="" style="object-fit: cover;">
			</div>
		</div>
		<!-- //循环项 -->
	</div>
</template>

<script>
	import aboutUsBorder from '@/components/aboutUsBorder.vue'
	import Swiper from 'swiper'
	import 'swiper/css/swiper.min.css'
	import { getCase } from '@/api'
	export default {
		data() {
			return {
				title: '合作案例',
				content: '铭智生科经过多年优秀的运营，凭借着业内良好的口碑，收获了一批批优质客户，以下为一些参考性案例。',
				list: [],
				//合作案例列表
				imgList: [{
						img: require('@/assets/images/partner/12306.webp')
					}, {
						img: require('@/assets/images/partner/beijing.webp')
					}, {
						img: require('@/assets/images/partner/chongqing.webp')
					}, {
						img: require('@/assets/images/partner/guoneng.webp')
					}, {
						img: require('@/assets/images/partner/guoyao.webp')
					}, {
						img: require('@/assets/images/partner/lepinhui.webp')
					}, {
						img: require('@/assets/images/partner/lepu.webp')
					}, {
						img: require('@/assets/images/partner/luhe.webp')
					}, {
						img: require('@/assets/images/partner/nongyedaxue.webp')
					}, {
						img: require('@/assets/images/partner/quanjude.webp')
					}, {
						img: require('@/assets/images/partner/red.webp')
					},
					{
						img: require('@/assets/images/partner/shoulv.webp')
					},
					{
						img: require('@/assets/images/partner/yidong.webp')
					},
					{
						img: require('@/assets/images/partner/yuanxin.webp')
					},
					{
						img: require('@/assets/images/partner/zhejiangyiliao.webp')
					},
				]
			};
		},

		components: { aboutUsBorder },

		created() {
			this.$store.commit('editAboutRouterName', this.$route.meta.title)

		},

		mounted() {
			this.getCase()

		},

		methods: {
			async getCase() {
				const res = await getCase()
				this.list = res.data
				this.$nextTick(() => {
					new Swiper('.swiper-container', {
						autoplay: true,
						slidesPerView: 3,
						spaceBetween: 30,
						centeredSlides: true,
						loop: true,
						pagination: {
							el: '.swiper-pagination',
							clickable: true,
						},
						navigation: {
							nextEl: '.swiper-button-next',
							prevEl: '.swiper-button-prev',
						},
					});
				})

			}
		}
	}
</script>
<style lang='scss' scoped>
	.swiper_box {
		margin: 0 auto;
		width: 1640px;
	}

	.swiper-container {
		width: 100%;
		height: 100%;
	}

	.swiper-slide {
		text-align: center;
		font-size: 18px;
		background: #fff;

		/* Center slide text vertically */
		display: -webkit-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		-webkit-justify-content: center;
		justify-content: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		-webkit-align-items: center;
		align-items: center;
		transition: 300ms;
		transform: scale(0.8);
	}

	.swiper-slide-active,
	.swiper-slide-duplicate-active {
		transform: scale(1);
	}

	.box_item:nth-child(3n + 0) {
		margin-right: 0 !important;
	}
</style>
